// Colors
$brand-primary: #232323;
$brand-sub: #969696;
$background: #161616;
$foreground: $brand-sub;

$text-color: $foreground;

// UIkit Override
$global-background: $background;
$global-primary-background: $foreground;
$global-secondary-background: $brand-primary;
$global-emphasis-color: lighten($text-color, 20);
$global-color: $text-color;
$global-muted-color: darken($text-color, 20);

$black: #000;
$white: #FFF;

// Dimes
$header-height: 80px;