.top {
    padding-bottom: 50px;

    .uk-section:first-child {
        padding-top: 30px;
    }
    .uk-section:last-child {
        padding-top: 80px;
    }
    .uk-section {
        padding-top: 30px;
    }
    .top-section-skills {
        .skills {
            margin: 0 auto;
            max-width: 480px;
            text-align: center;

            .skill-large {
                display: inline-block;
                padding: 4px 16px;
                margin-right: 8px;
                margin-bottom: 24px;
                font-size: 1.0rem;
                font-weight: 300;
                letter-spacing: 1px;
                border: 1px solid $foreground;
                border-radius: 50px;
            }
        }
    }
    .top-section-history {
        display: flex;
        flex-direction: row;
        justify-content: center;
        font-weight: 200;
        letter-spacing: 1px;

        .left {
            padding-right: 30px;
            border-right: 1px solid $foreground;
            font-size: 0.8rem;
            line-height: 1.5rem;
        }
        .right {
            padding-left: 40px;
        }
    }
    .top-section-about {
        text-align: center;

        h5{
            font-weight: 300;
            letter-spacing: 1px;
        }
        p {
            font-weight: 200;
        }
        .snses {
            .uk-icon {
                margin: 0 8px;

                span { 
                    display: none;
                }
            }
        }
    }
    #parallax-target {
        overflow: hidden;
        height: 300px;
        background: linear-gradient(135deg, $background, $black);
        text-align: right;

        .octett-parallax {
            margin-top: -60px;
            margin-right: -60px;

            .octettruss-svg {
                fill: $foreground;
                width: 700px;
                opacity: 0.5;
            }
        }
    }
}

@media (max-width: $breakpoint-xsmall-max) {
    .top {
        .top-section-history {
            .left {
                padding-right: 15px;
                border-right: 1px solid $foreground;
                font-size: 0.8rem;
                line-height: 1.5rem;
            }
            .right {
                padding-left: 20px;
            }
        }
        #parallax-target {
            height: 200px;
            
            .octett-parallax {
                margin-top: -30px;
                margin-right: -60px;
    
                .octettruss-svg {
                    width: 500px;
                    min-width: 500px;
                }
            }
        }
    }
}
