.detail {
    padding-bottom: 50px;

    h1 {
        font-weight: 300;
        letter-spacing: 1px;
    }
    .skills {
        .skill-large {
            display: inline-block;
            padding: 4px 16px;
            margin-right: 8px;
            margin-bottom: 4px;
            font-size: 0.8rem;
            font-weight: 300;
            letter-spacing: 1px;
            border: 1px solid $foreground;
            border-radius: 50px;
        }
    }
    .description {
        
    }
    .images {
        text-align: center;

        img {
            width: 100%;
            margin: 12px 0;
        }
        video {
            margin: 12px 0;
        }
    }
}