.header {
    position: sticky;
    top: 0px;
    background: $background;
    z-index: 10;
}

#header {
    width: 100%;
    color: white;
    

    .uk-navbar {
        .uk-navbar-left {
            .uk-logo {
                font-weight: 300;

                .logo-image {
                    height: 32px;
                }
                .logo-title {
                    margin-left: 4px;
                }
            }
        }
        .uk-navbar-toggle {
            cursor: pointer;
        }
    }
}
#sidenav {
    .uk-offcanvas-close {
        padding-top: 13px;
        padding-right: 0px;
    }
}