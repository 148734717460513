.about {
    padding-bottom: 50px;
    text-align: center;

    .snses {
        a.uk-icon {
            margin: 0 8px;
            
            span {
                display: none;
            }
        }
    }
}