.eyecatch {
    position: relative;
    display: flex;
    justify-content: center;
    padding-top: 40px;
    height: 250px;
    background: linear-gradient(135deg, $background, $black);

    .cover {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0.3;

        &.cover-enter {
            opacity: 0;
            transform: translateX(20px);
        }
        &.cover-enter-active {
            opacity: 0.3;
            transform: translateX(0px);
            transition: opacity 300ms, transform 300ms ease;
        }
        &.cover-exit {
            opacity: 0.3;
            transform: translateX(0px);
        }
        &.cover-exit-active {
            opacity: 0;
            transform: translateX(20px);
            transition: opacity 300ms, transform 300ms ease;
        }
        &.cover-exit-done {
            opacity: 0;
            transform: translateX(20px);
            transition: opacity 300ms, transform 300ms ease;
        }
    }
    .cover-strong {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 1.0;

        &.cover-enter {
            opacity: 0;
        }
        &.cover-enter-active {
            opacity: 1.0;
            transition: opacity 300ms;
        }
        &.cover-exit {
            opacity: 1.0;
            transform: translateX(0px);
        }
        &.cover-exit-active {
            opacity: 0;
            transition: opacity 300ms;
        }
        &.cover-exit-done {
            opacity: 0;
            transition: opacity 300ms;
        }
    }

    .octettruss-svg {
        position: absolute;
        height: 230px;

        g {
            path {
                stroke: $foreground;
                fill: $foreground;

                &.path {
                    stroke-dasharray: 5000;
                    stroke-dashoffset: 0;
                    stroke-width: 6;
                    animation: path_anime 2s forwards;
                }
                // &:nth-child(1) {
                //     opacity: 0.0;
                //     transform: translate(-300px, 300px);
                //     transition: 200ms ease-out;
                // }
                &:nth-child(2) {
                }
                &:nth-child(3) {
                }
                &:nth-child(4) {
                }
                &:nth-child(5) {
                }
                &:nth-child(6) {
                }
                &:nth-child(7) {
                }
            }
            &:hover {
                path {
                    &:nth-child(1) {
                        opacity: 1.0;
                        transform: translate(0px, 0px);
                    }
                    &:nth-child(2) {   
                    }
                    &:nth-child(3) {
                    }
                    &:nth-child(4) {                        
                    }
                    &:nth-child(5) {
                    }
                    &:nth-child(6) {
                    }
                    &:nth-child(7) {
                    }
                }
            }
        }
    }
}

@media (max-width: $breakpoint-xsmall-max) {
    .eyecatch {
        padding-top: 20px;
        height: 200px;

        .octettruss-svg {
            height: 192px;
        }
    }
}

@keyframes path_anime {
    0% {
        stroke-dashoffset: 5000;
        fill: transparent;
    }
    70% {
        stroke-dashoffset: 0;
    }
    75% {
        fill: transparent;
    }
    100% {
        fill: $foreground;
    }
}