header {

}
main {
    position: relative;

    .router-page {
        position: relative;
        left: 0px;
        right: 0px;
    }
    .router-page-enter-active {
        opacity: 0;
        transform: translateY(10px);
    }
    .router-page-enter-done {
        opacity: 1;
        transform: translateY(0px);
        transition: opacity 300ms, transform 300ms;
    }
    .router-page-exit-active {
        display: none;
    }
    .router-page-exit-done {

    }
}

.uk-section {
    .uk-container {
        h2 {
            margin-bottom: 48px;
            font-size: 1.5rem;
            font-weight: 100;
            letter-spacing: 8px;
            text-align: center;
        }
    }
}