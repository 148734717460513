@import "common/variables";
@import "common/fonts";
@import "common/layouts";

@import "uikit/src/scss/variables-theme.scss";
@import "uikit/src/scss/mixins-theme.scss";
@import "uikit/src/scss/uikit-theme.scss";

@import "common/controls";

@import "components/header";
@import "components/footer";
@import "components/top";
@import "components/top/eyecatch";
@import "components/detail";
@import "components/about";
@import "components/signin";
@import "components/ui-parts/workcard";

html {
    background: $background;
}
body {
    color: $text-color;
    background: $background;
}