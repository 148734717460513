.signin {
    padding-bottom: 50px;

    .uk-container {
        max-width: 420px;
    }
    h5 {
        text-align: center;
    }
    .form-error-text{
        padding: 4px 0;
        color: $alert-danger-color;
    }
}