.workcard {
    a {
        text-decoration: none;

        .uk-card {
            &:hover {
                box-shadow: 0 14px 25px rgba(0, 0, 0, 0.5);
            }

            .uk-card-title {
                font-size: 1.3rem;
                margin-bottom: 8px;
                font-weight: 300;
                letter-spacing: 1px;
            }
            .year {
                margin: 0;
                font-size: 0.8rem;
                font-weight: 100;
                letter-spacing: 1px;
            }
            .skills {
                margin-top: 16px;

                .skill-large {
                    display: inline-block;
                    padding: 4px 16px;
                    margin-right: 8px;
                    margin-bottom: 4px;
                    font-size: 0.8rem;
                    font-weight: 300;
                    letter-spacing: 1px;
                    border: 1px solid $foreground;
                    border-radius: 50px;
                }
            }
        }
    }
}